@tailwind base;
@import "components.css";
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  @apply text-gray-900 tracking-[0.65px] font-normal;
}

span {
  @apply text-[17px]
}

.slick-slider .slick-dots li {
  @apply w-max h-max inline-block;
}

.slick-slider .slick-dots li button:before {
  @apply hidden;
}

.slick-slider .slick-dots li button {
  @apply p-0 w-1.5 h-1 bg-white rounded-sm hover:bg-primary-400;
}

.slick-slider li.slick-active button {
  @apply w-6 bg-primary-400;
}

.slick-slider .slick-arrow.slick-prev:before {
  content: ''
}

.slick-slider .slick-arrow.slick-next:before {
  content: ''
}

.slick-slider:focus-visible {
  @apply border-0 outline-0;
}

@layer utilities {
  .scrollbar-hide {
      -ms-overflow-style: none;
      scrollbar-width: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
      display: none;
  }
}